import { useState, useEffect, useCallback, useMemo } from 'react';
import { FiRefreshCw, FiX, FiCheck, FiTrash2 } from 'react-icons/fi';

import { Container } from './styles';

import TableList from '~/components/TableList';
import ReprocessDialog from '~/components/Dialogs/ReprocessDialog';
import { useOrder } from '~/providers/orders';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'pedido_venda_ecommerce_id', label: 'Pedido Bling' },
  { id: 'cliente_erp_id_view', label: 'Integração Cliente - SAP' },
  { id: 'pedido_venda_erp_id_view', label: 'Integração Venda - SAP' },
  {
    id: 'pedido_compra_ecommerce_id_view',
    label: 'Integração Compra - Bling',
  },
];

const otherCells = [
  { id: 'mensagem_erro', label: 'Mensagem de erro' },
  { id: 'cliente_nome', label: 'Nome do Cliente' },

  { id: 'data', label: 'Data do Pedido' },
];

const observationCells = [
  { id: 'observacoes', label: 'Observações gerais' },
  { id: 'observacao_interna', label: 'Observações internas' },
];

function Dashboard() {
  const { orders, orderListLoading, destroy, index } = useOrder();
  const [loaded, setLoaded] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const ordersParsed = useMemo(
    () =>
      orders.map(order => {
        let cliente_erp_id = 'Aguardando Envio';
        let clientIcon = <FiRefreshCw size={20} color="#fcba03" />;
        let pedido_venda_erp_id = 'Aguardando Envio';
        let vendaIcon = <FiRefreshCw size={20} color="#fcba03" />;
        let pedido_compra_ecommerce_id = 'Aguardando Envio';
        let compraIcon = <FiRefreshCw size={20} color="#fcba03" />;

        if (!order.cliente_erp_id && order.tentativas >= 1) {
          cliente_erp_id = 'Erro na integração';
          clientIcon = <FiX size={20} color="red" />;
        } else if (order.cliente_erp_id) {
          cliente_erp_id = order.cliente_erp_id;
          clientIcon = <FiCheck size={20} color="green" />;
        }

        if (
          order.cliente_erp_id &&
          !order.pedido_venda_erp_id &&
          order.tentativas >= 1
        ) {
          pedido_venda_erp_id = 'Erro na integração';
          vendaIcon = <FiX size={20} color="red" />;
        } else if (order.pedido_venda_erp_id) {
          pedido_venda_erp_id = order.pedido_venda_erp_id;
          vendaIcon = <FiCheck size={20} color="green" />;
        }

        if (
          order.pedido_venda_erp_id &&
          !order.pedido_compra_ecommerce_id &&
          order.tentativas >= 1
        ) {
          pedido_compra_ecommerce_id = 'Erro na integração';
          compraIcon = <FiX size={20} color="red" />;
        } else if (order.pedido_compra_ecommerce_id) {
          pedido_compra_ecommerce_id = order.pedido_compra_ecommerce_id;
          compraIcon = <FiCheck size={20} color="green" />;
        }

        return {
          ...order,
          cliente_erp_id_view: (
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {clientIcon}
              {cliente_erp_id}
            </div>
          ),
          pedido_venda_erp_id_view: (
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {vendaIcon}
              {pedido_venda_erp_id}
            </div>
          ),
          pedido_compra_ecommerce_id_view: (
            <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              {compraIcon}
              {pedido_compra_ecommerce_id}
            </div>
          ),
        };
      }),
    [orders]
  );

  const loadOrders = useCallback(
    async (search = '', order_by = 'id', order = 'desc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const reprocessOrder = useMemo(
    () => ({
      label: 'Reprocessar ',
      handleFunction: async () => {
        setShowDialog(true);
      },
    }),
    []
  );

  const actions = useMemo(() => {
    const auxActions = [];

    auxActions.push({
      label: 'Remover',
      icon: <FiTrash2 size={20} />,
      handleFunction: order => {
        destroy({ order: { id: order.id, uuid: order.uuid, req_id: '1' } });
      },
    });

    return auxActions;
  }, [destroy]);

  useEffect(() => {
    if (!loaded && orders.length <= 1) loadOrders();

    setLoaded(true);
  }, [orders, loadOrders, loaded]);

  return (
    <Container>
      <ReprocessDialog showDialog={showDialog} setShowDialog={setShowDialog} />
      <TableList
        newItem={reprocessOrder}
        headCells={headCells}
        data={ordersParsed}
        otherCells={otherCells}
        observationCells={observationCells}
        loadData={loadOrders}
        loading={orderListLoading}
        actions={actions}
      />
    </Container>
  );
}

export default Dashboard;
