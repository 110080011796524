import { useEffect, useState, useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi';

import { Container } from './styles';

import { useAuth } from '~/providers/auth';
import { useUser } from '~/providers/user';

import TableList from '~/components/TableList';

const headCells = [
  { id: 'cpf_cnpj', label: 'CPF/CNPJ' },
  { id: 'name', label: 'Nome do Usuário' },
  { id: 'email', label: 'Email' },
];

function Users() {
  const history = useHistory();

  const { user } = useAuth();
  const { users, userListLoading, index, show, destroy } = useUser();
  const [loaded, setLoaded] = useState(false);

  const loadUsers = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () => ({
      label: 'Adicionar Usuário ',
      handleFunction: () => {
        history.push('/users/form', { newUser: true });
      },
    }),
    [history]
  );

  const actions = useMemo(() => {
    const auxActions = [];

    auxActions.push({
      label: 'Visualizar',
      icon: <FiEye size={20} />,
      handleFunction: async auxUser => {
        if (user.login !== 'admin' && user.uuid !== auxUser.uuid) {
          toast.warn('Você não tem permissões para visualizar esse usuário.');
        } else {
          history.push('/users/form', { showUser: true });

          await show({ userUuid: auxUser.uuid });
        }
      },
    });

    auxActions.push({
      label: 'Editar',
      icon: <FiEdit2 size={20} />,
      handleFunction: async auxUser => {
        if (user.login !== 'admin' && user.uuid !== auxUser.uuid) {
          toast.warn('Você não tem permissões para editar esse usuário.');
        } else {
          history.push('/users/form', { updateUser: true });

          await show({ userUuid: auxUser.uuid });
        }
      },
    });

    auxActions.push({
      label: 'Remover',
      icon: <FiTrash2 size={20} />,
      handleFunction: auxUser => {
        if (user.login !== 'admin' && user.uuid !== auxUser.uuid) {
          toast.warn('Você não tem permissões para excluir esse usuário.');
        } else {
          destroy({ user: auxUser });
        }
      },
    });

    return auxActions;
  }, [user, history, show, destroy]);

  useEffect(() => {
    if (!loaded && users.length <= 1) loadUsers();

    setLoaded(true);
  }, [users, loadUsers, loaded]);

  return (
    <Container>
      <TableList
        newItem={newItem}
        headCells={headCells}
        data={users}
        loadData={loadUsers}
        loading={userListLoading}
        actions={actions}
      />
    </Container>
  );
}

export default Users;
